import axiosInstance from "./Api";
import AwayLogo from "@/assets/images/logo/away.png";
import { RAZORPAY_KEY_ID } from "@/config";

const PaymentServices = {
  async displayRazorPay(payload) {
    const { plan_name, plan_id, userProfile, razorpayPaymentResponse } =
      payload;

    try {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      // creating a new order
      const result = await axiosInstance().get(
        `/student/order-id-for-plan/${plan_id}`
      );

      if (!result.data.status) {
        console.log("Server error");
        razorpayPaymentResponse({ status: false });
        return;
      }

      // Getting the order details back
      const { amount, id: order_id, currency } = result.data.data.order;
      const { purchase_name, transaction_id } = result.data.data;

      const options = {
        key: RAZORPAY_KEY_ID,
        amount: amount.toString(),
        currency: currency,
        name: "Away Education",
        description: purchase_name,
        image: { AwayLogo },
        order_id: order_id,
        handler: async function (response) {
          const data = {
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
            transaction_id,
          };

          const result = await axiosInstance().post(
            "/student/verify-payment-for-plan",
            data
          );

          razorpayPaymentResponse(result.data);
        },
        prefill: {
          contact: userProfile.user_mobile ? userProfile.user_mobile : null,
          email: userProfile.user_email ? userProfile.user_email : null,
        },
        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

      paymentObject.on("payment.failed", function (response) {
        console.log("Paymnet failed ", response);
        razorpayPaymentResponse({ status: false });
      });
    } catch (err) {
      console.log("Error in display razorpay :- ", err);
      razorpayPaymentResponse({ status: false });
    }
  },

  async displayRazorPayForAgent(payload) {
    const {
      plan_name,
      plan_id,
      userProfile,
      student_user_id,
      razorpayPaymentResponse,
    } = payload;

    try {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      // creating a new order
      const result = await axiosInstance().get(
        `/agent/order-id-for-plan/${student_user_id}/${plan_id}`
      );

      if (!result.data.status) {
        console.log(result);
        console.log("Server error");
        razorpayPaymentResponse({ status: false });
        return;
      }

      // Getting the order details back
      const { amount, id: order_id, currency } = result.data.data.order;
      const { purchase_name, transaction_id } = result.data.data;

      const options = {
        key: RAZORPAY_KEY_ID,
        amount: amount.toString(),
        currency: currency,
        name: "Away Education",
        description: purchase_name,
        image: { AwayLogo },
        order_id: order_id,
        handler: async function (response) {
          const data = {
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
            transaction_id,
          };

          const result = await axiosInstance().post(
            "/agent/verify-payment-for-plan/",
            data
          );

          razorpayPaymentResponse(result.data);
        },
        prefill: {
          contact: userProfile.user_mobile ? userProfile.user_mobile : null,
          email: userProfile.user_email ? userProfile.user_email : null,
        },
        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

      paymentObject.on("payment.failed", function (response) {
        console.log("Paymnet failed ", response);
        razorpayPaymentResponse({ status: false });
      });
    } catch (err) {
      console.log("Error in display razorpay :- ", err);
      razorpayPaymentResponse({ status: false });
    }
  },
};

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export default PaymentServices;
